import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './Question.css'; // Importing the CSS file
import { jwtDecode } from 'jwt-decode';


const backendUrl = process.env.REACT_APP_BACKEND_URL;
const getUserIdFromToken = () => {
    const token = localStorage.getItem('userToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken.userID; // 根据你的 token 结构调整
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    }
    return null;
  };
function Question(props) {
    const [warningMessage, setWarningMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate(); // 用于导航
    const params = useParams(); // 获取 URL 参数
    const urlQuestionId = params.questionId; // 假设路由是 `/question/:questionId`
    const [questionIds, setQuestionIds] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [isFlagged, setIsFlagged] = useState(false); // 新增的 state 来跟踪问题的标记状态
    const [isSubmitted, setIsSubmitted] = useState(false); // State to track submission
    const [userAnswered, setUserAnswered] = useState(false); // 用户是否回答过当前问题
    const [userAnswer, setUserAnswer] = useState(''); // 用户的答案
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [viewAnswer, setViewAnswer] = useState(false);

    const [hasNextQuestion, setHasNextQuestion] = useState(false);
    const [nextQuestionId, setNextQuestionId] = useState(null);
    const handleGoToReview = () => {
        navigate('/review'); // 确保这是你的 Review Screen 的路由
    };

    const checkIfFlagged = async (userId, questionId) => {
        try {
            const response = await axios.post(`${backendUrl}/api/questions/get-flag-status`, {
                userId: userId,
                questionId: questionId
            });
            return response.data.flagged;
        } catch (error) {
            console.error('Error checking flag status:', error);
            return false;
        }
    };

    const toggleFlagStatus = async () => {
        try {
            const userId = getUserIdFromToken();
            const questionId = questionIds[currentQuestionIndex];

            if (userId && questionId) {
                const response = await axios.post(`${backendUrl}/api/questions/user/${userId}/question/${questionId}/flag`, {
                    flag: !isFlagged
                });

                if (response.status === 200) {
                    setIsFlagged(!isFlagged); // 更新标记状态
                    console.log('Flag status updated successfully');
                }
            }
        } catch (error) {
            console.error('Error updating flag status:', error);
        }
    };
    // 返回 Dashboard
    const handleBackToDashboard = () => {
        navigate('/dashboard'); // 替换为你的 dashboard 路由
    };
            // 注销
    const handleLogout = () => {
        localStorage.removeItem('userToken'); // 移除 token
        navigate('/'); // 导航到登录页面，替换为你的登录路由
    };
    const handleSubmit = async () => {
        if (!selectedAnswer) {
            setWarningMessage('Please select an answer before submitting.');
            return; // 不继续执行后续代码
        }
            // 重置警告消息并继续提交逻辑
        setWarningMessage('');
        setIsSubmitted(true);
        setIsSubmitted(true);
      
        const userId = getUserIdFromToken();
        const questionId = questionIds[currentQuestionIndex];
      
        if (userId && questionId) {
          try {
            const response = await axios.post(`${backendUrl}/api/submit-answer`, {
              userId: userId,
              questionId: questionId,
              userAnswer: selectedAnswer
            });
      
            if (response.status === 200) {
              console.log('Answer submitted successfully');
              // 可以添加其他逻辑
            }
          } catch (error) {
            console.error('Error submitting answer:', error);
            // 处理错误
          }
        }
        setSubmitted(true);
      };
      

    const handleAnswerChange = (value) => {
        setSelectedAnswer(value);
    };

    const handleViewAnswer = () => {
        setViewAnswer(true);
    };

    const getAnswerChoiceClass = (choice) => {
        if (viewAnswer) {
            if (choice === currentQuestion.correctAnswer) {
                return 'answer-choice correct';
            } else if (choice === selectedAnswer) {
                return 'answer-choice incorrect';
            }
        }
        return 'answer-choice';
    };

    const fetchQuestion = async (questionId) => {
        try {
            const userId = getUserIdFromToken();
            const flaggedStatus = await checkIfFlagged(userId, questionId);
            setIsFlagged(flaggedStatus);

            const answerResponse = await axios.get(`${backendUrl}/api/user/${userId}/question/${questionId}/answer`);
            if (answerResponse.data.answered && answerResponse.data.userAnswer !== '') {
                setUserAnswered(true);
                setIsSubmitted(true);
                setUserAnswer(answerResponse.data.userAnswer);
                setViewAnswer(true);
            } else {
                setUserAnswered(false);
                setUserAnswer('');
                setViewAnswer(false);
            }

            const questionResponse = await axios.get(`${backendUrl}/api/questions/question/${questionId}`);
            if (questionResponse.data) {
                setCurrentQuestion(questionResponse.data.questionData); // 设置问题数据
                if (urlQuestionId) {
                    setHasNextQuestion(questionResponse.data.hasNextQuestion);
                    setNextQuestionId(questionResponse.data.nextQuestionId);
                }
            } else {
                console.error('No question data received');
            }
        } catch (error) {
            console.error('Error fetching question or checking answer:', error);
        }
    };

    useEffect(() => {
        // 如果 URL 中有 questionId，则使用它
        if (urlQuestionId) {
            setQuestionIds([urlQuestionId]);
            setCurrentQuestionIndex(0);
        }
        // 否则，检查是否从 props 获取 trialQuestionIds
        else if (props.trialQuestionIds && props.trialQuestionIds.length > 0) {
            setQuestionIds(props.trialQuestionIds);
            setCurrentQuestionIndex(0);
        }
        // 最后，尝试从路由状态中获取 unansweredQuestionIds
        else if (location.state?.unansweredQuestionIds) {
            const ids = location.state.unansweredQuestionIds;
            setQuestionIds(ids.sort(() => 0.5 - Math.random()));
        }
    }, [urlQuestionId, props.trialQuestionIds, location.state]);
    

    useEffect(() => {
        if (questionIds.length > 0 && currentQuestionIndex < questionIds.length) {
            fetchQuestion(questionIds[currentQuestionIndex]);
        }
    }, [currentQuestionIndex, questionIds]);
    

    const handleNextQuestion = () => {
        if (urlQuestionId && hasNextQuestion) {
            navigate(`/question/${nextQuestionId}`);
        } else if (!urlQuestionId && currentQuestionIndex < questionIds.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        setIsSubmitted(false);
        setSelectedAnswer('');
        setSubmitted(false);
        setViewAnswer(false);
    };

    const handlePreviousQuestion = () => {
        if (urlQuestionId) {
            const nextQuestionId = parseInt(urlQuestionId) - 1;
            navigate(`/question/${nextQuestionId}`);
            setIsSubmitted(false);
            setSelectedAnswer('');
            setSubmitted(false);
            setViewAnswer(false);
        } else if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setIsSubmitted(false);
            setSelectedAnswer('');
            setSubmitted(false);
            setViewAnswer(false);
        }
    };

    const renderQuestion = () => {
        if (!currentQuestion) {
            return <div>Loading question...</div>;
        }

        const labeledAnswers = currentQuestion.answerChoices.map((answer, index) => ({
            label: String.fromCharCode(65 + index),
            text: answer
        }));

        // 将文字 '\n' 替换为实际的换行符
        const formattedQuestionText = currentQuestion.questionText.replace(/\\n/g, '\n').split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));

        return (
            <div>
                <div className="question-flag-container">
                    <button onClick={toggleFlagStatus} className={`flag-button ${isFlagged ? 'flagged' : ''}`}>
                        {isFlagged ? 'Unflag' : 'Flag'}
                    </button>
                    <p className="question-id">Question ID: {questionIds[currentQuestionIndex]}</p>
                </div>   
                <h2 className="question-title">{formattedQuestionText}</h2>
                {labeledAnswers.map((answer, index) => (
                    <div key={index} className={getAnswerChoiceClass(answer.text)}>
                        <input
                            type="radio"
                            value={answer.text}
                            checked={userAnswered ? answer.text === userAnswer : selectedAnswer === answer.text}
                            id={`answer-${index}`}
                            name="answer"
                            className="answer-input"
                            onChange={() => handleAnswerChange(answer.text)}
                            disabled={isSubmitted || userAnswered}
                        />
                        <label htmlFor={`answer-${index}`} className="answer-label">
                            {answer.label}. {answer.text}
                        </label>
                    </div>
                ))}
                {viewAnswer && (
                    <div className="explanation">
                        <p>{currentQuestion.explanation}</p>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="question-container">
                        {/* 右上角的按钮 */}
            {!props.hideTopRightButtons && (
                <div className="top-right-buttons">
                    <button onClick={handleBackToDashboard} className="back-dashboard-button">Dashboard</button>
                    <button onClick={handleGoToReview} className="review-screen-button">Review</button>
                    <button onClick={handleLogout} className="logout-button">Logout</button>
                </div>
            )}
            {warningMessage && <div className="warning-message">{warningMessage}</div>}
            {renderQuestion()}

            {!isSubmitted && (
                <button onClick={handleSubmit} className="submit-button">Submit Answer</button>
            )}
            {submitted && !viewAnswer && (
                <button onClick={handleViewAnswer} className="view-answer-button">View Answer</button>
            )}
{/*            {viewAnswer && (
                <div className="explanation">
                    <p>{currentQuestion.explanation}</p>
                </div>
)}*/}
            <div className="navigation-buttons">
                {(currentQuestionIndex > 0 || (urlQuestionId && parseInt(urlQuestionId) > 1)) && (
                    <button onClick={handlePreviousQuestion} className="prev-button">Previous Question</button>
                )}
                {(urlQuestionId && hasNextQuestion) || (!urlQuestionId && currentQuestionIndex < questionIds.length - 1) ? (
                    <button onClick={handleNextQuestion} className="next-button">Next Question</button>
                ) : null}
            </div>
            {/* Footer 区域包含版权声明 */}
            <footer className="footer">
                <p>&copy; {new Date().getFullYear()} Chengda Zhang MD MS. All Rights Reserved.</p>
            </footer>
        </div>
    );
}

export default Question;