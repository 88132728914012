import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from react-router-dom
import './SignUp.css'; // Create a new CSS file for SignUp
import axios from 'axios'; // Ensure you have axios installed or use fetch API

const backendUrl = process.env.REACT_APP_BACKEND_URL;
function SignUp() {
    const navigate = useNavigate(); // Create an instance of useNavigate
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match.'); // Add simple validation for password match
            return;
        }
        try {
            const response = await axios.post(`${backendUrl}/register`, { 
                username, 
                email, 
                password 
            });
            console.log(response.data); // Handle the response as needed
            alert('Registration successful! Please log in.'); // Notify the user that registration was successful
            navigate('/'); // Redirect to login page on successful signup
        } catch (error) {
            console.error('Error registering user:', error.response.data);
            // Handle errors here
        }
    };

    return (
        <div className="signup-container">
            <h1>Sign Up</h1>
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    placeholder="Username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                />
                <input 
                    type="email" 
                    placeholder="Email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                />
                <input 
                    type="password" 
                    placeholder="Password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                />
                                <input 
                    type="password" 
                    placeholder="Confirm Password" 
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                />
                <button type="submit">Sign Up</button>
            </form>
        </div>
    );
}

export default SignUp;
