import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../UserContext'; // Import UserContext

const ProtectedRoute = ({ children }) => {
    const { userData, isLoading } = useContext(UserContext); // 获取整个 userData 对象
    //console.log('User Data in Component:', userData);

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (!userData || !userData.isSubscriptionActive) {
        return <Navigate to="/trial-dashboard" />;
    }

    return children;
};

export default ProtectedRoute;
