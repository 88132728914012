import React, { useState } from 'react';
import axios from 'axios';
import './EditQuestion.css'; // 确保路径正确

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const QuestionEditor = () => {
    const [inputQuestionId, setInputQuestionId] = useState('');
    const [question, setQuestion] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchQuestion = async () => {
        const token = localStorage.getItem('userToken');
        try {
            setLoading(true);
            const response = await axios.get(`${backendUrl}/api/get-questions/${inputQuestionId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setQuestion(response.data);
            setLoading(false);
        } catch (err) {
            if (err.response && err.response.status === 403) {
                setError('Access denied. Admin privileges required.');
            } else {
                setError('Error fetching question data');
            }
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setInputQuestionId(e.target.value);
    };

    const handleQuestionChange = (e) => {
        setQuestion({ ...question, [e.target.name]: e.target.value });
    };

    const handleFetchClick = () => {
        if (inputQuestionId) {
            fetchQuestion();
        } else {
            setError('Please enter a valid Question ID');
        }
    };

    const updateQuestion = async () => {
        const token = localStorage.getItem('userToken');
        try {
            setLoading(true);
            await axios.put(`${backendUrl}/api/edit-questions/${inputQuestionId}`, question, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            alert('Question updated successfully!');
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="editor-container">
            <input
                type="text"
                value={inputQuestionId}
                onChange={handleInputChange}
                placeholder="Enter Question ID"
                className="input-field"
            />
            <button onClick={handleFetchClick} className="button">Fetch Question</button>

            {isLoading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}

            {question && (
                <div>
                    <h2>Edit Question</h2>
                    <div>
                        <label className="label">Question Text:</label>
                        <input
                            type="text"
                            name="QuestionText"
                            value={question.QuestionText || ''}
                            onChange={handleQuestionChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Answer Choice 1:</label>
                        <input
                            type="text"
                            name="QuestionAnswerChoice1"
                            value={question.QuestionAnswerChoice1 || ''}
                            onChange={handleQuestionChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Answer Choice 2:</label>
                        <input
                            type="text"
                            name="QuestionAnswerChoice2"
                            value={question.QuestionAnswerChoice2 || ''}
                            onChange={handleQuestionChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Answer Choice 3:</label>
                        <input
                            type="text"
                            name="QuestionAnswerChoice3"
                            value={question.QuestionAnswerChoice3 || ''}
                            onChange={handleQuestionChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Answer Choice 4:</label>
                        <input
                            type="text"
                            name="QuestionAnswerChoice4"
                            value={question.QuestionAnswerChoice4 || ''}
                            onChange={handleQuestionChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Correct Answer:</label>
                        <input
                            type="text"
                            name="QuestionAnswer"
                            value={question.QuestionAnswer || ''}
                            onChange={handleQuestionChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Explanation:</label>
                        <textarea
                            name="QuestionExplain"
                            value={question.QuestionExplain || ''}
                            onChange={handleQuestionChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Category:</label>
                        <input
                            type="text"
                            name="QuestionCategory"
                            value={question.QuestionCategory || ''}
                            onChange={handleQuestionChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label>Is Inactive:</label>
                        <input
                            type="checkbox"
                            name="Inactive"
                            checked={question.Inactive}
                            onChange={(e) => setQuestion({ ...question, Inactive: e.target.checked })}
                        />
                    </div>
                    <div>
                        <label>Is Trial Question:</label>
                        <input
                            type="checkbox"
                            name="IsTrialQuestion"
                            checked={question.IsTrialQuestion}
                            onChange={(e) => setQuestion({ ...question, IsTrialQuestion: e.target.checked })}
                        />
                    </div>
                    
                    <button onClick={updateQuestion} className="button">Update Question</button>
                </div>
            )}
        </div>
    );
};

export default QuestionEditor;
