import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentResult.css';

const PaymentResult = () => {
    const navigate = useNavigate();

    // Redirect to UserDashboard after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/dashboard'); // Replace '/userdashboard' with your actual UserDashboard route
        }, 5000); // 5000 milliseconds = 5 seconds

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, [navigate]);

    return (
        <div className="payment-result">
            <h1>Payment Result</h1>
            <div>
                <h2>Payment Successful!</h2>
                <p>Your subscription has been activated.</p>
            </div>
        </div>
    );
};

export default PaymentResult;
