import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FrontPage from './components/FrontPage';
import ForgotPassword from './components/ForgotPassword';
import UserDashboard from './components/UserDashboard';
import Question from './components/Question';
import Payment from './components/Payment';
import PaymentResult from './components/PaymentResult';
import SignUp from './components/SignUp';
import TrialDashboard from './components/TrialDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Account from './components/Account';
import ReviewScreen from './components/ReviewScreen';
import Upload from './components/Upload';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UserProvider } from './UserContext';
import Delete from './components/Delete';
import ResetPassword from './components/ResetPassword';
import RetrieveUsername from './components/RetrieveUsername';
import AdminRoute from './components/AdminRoute';
import EditQuestion from './components/EditQuestion';
import EditUser from './components/EditUser';
import About from './components/About';
import Contact from './components/Contact';

const stripePromise = loadStripe("pk_test_51OGVDlEHbQSJYkrFGOJH9o7EFgDlBRUAFar7dP3S3I5C41HVmXxdCgLoyE7ZmFsXzVQsUJY185ZmL2Vo9WqvvTZn00sFTkCZfd");

function App() {
    return (
        <Router>
            <Elements stripe={stripePromise}>
                <UserProvider>
                    <div className="App">
                        <Routes>
                            <Route path="/" element={<FrontPage />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset-password/:token" element={<ResetPassword />} /> {/* 添加这个路由 */}
                            <Route path="/question" element={<Question />} />
                            <Route path="/payment" element={<Payment />} />
                            <Route path="/payment-result" element={<PaymentResult />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/trial-dashboard" element={<TrialDashboard />} />
                            <Route path="/account" element={<Account />} />
                            <Route path="/RetrieveUsername" element={<RetrieveUsername/>} />
                            <Route path="/about" element={<About/>} />
                            <Route path="/contact" element={<Contact/>} />
                            
                            {/* 使用 AdminRoute 保护 Upload 和 Delete 路由 */}
                            <Route path="/Upload" element={
                                <AdminRoute>
                                    <Upload />
                                </AdminRoute>
                            } />
                            <Route path="/Delete" element={
                                <AdminRoute>
                                    <Delete />
                                </AdminRoute>
                            } />
                            <Route path="/Edit" element={
                                <AdminRoute>
                                    <EditQuestion />
                                </AdminRoute>
                            } />
                            <Route path="/EditUser" element={
                                <AdminRoute>
                                    <EditUser />
                                </AdminRoute>
                            } />

                            {/* 使用 ProtectedRoute 保护其他受限路由 */}

                            <Route path="/dashboard" element={
                                <ProtectedRoute>
                                    <UserDashboard />
                                </ProtectedRoute>
                            } />
                            <Route path="/review" element={
                                <ProtectedRoute>
                                    <ReviewScreen />
                                </ProtectedRoute>
                            } />
                            <Route path="/question/:questionId" element={
                                <ProtectedRoute>
                                    <Question />
                                </ProtectedRoute>
                            } />
                        </Routes>
                    </div>
                </UserProvider>
            </Elements>
        </Router>
    );
}

export default App;
