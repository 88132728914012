import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate for redirection
import axios from 'axios';
import { UserContext } from '../UserContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function UserLogin() {
    const { setUserData } = useContext(UserContext); // 使用UserContext
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate(); // Initialize navigate

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${backendUrl}/login`, {
                username: username,
                password: password
            });
            //console.log('Login response:', response.data);
            if (response.data.token) {
                localStorage.setItem('userToken', response.data.token); // Store the token
                //console.log('Token stored:', localStorage.getItem('userToken'));
                // 使用从登录响应中获得的数据更新UserContext状态
                setUserData({ isSubscriptionActive: response.data.isSubscriptionActive });

                // Redirect based on subscription status
                if (response.data.isSubscriptionActive) {
                    navigate('/dashboard');
                } else {
                    navigate('/trial-dashboard');
                }
            } else {
                // Handle case when no token is returned
                setLoginError('Login failed. No token received.');
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                setLoginError(error.response.data);
            } else {
                setLoginError('Login failed. Please try again later.');
            }
        }
    };

    return (
        <div className="login-container">
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
                <input 
                    type="text" 
                    placeholder="Username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                />
                <input 
                    type="password" 
                    placeholder="Password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                />
                <button type="submit">Login</button>
                {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
            </form>
            <Link to="/forgot-password">Forgot Password?</Link> {/* 保留忘记密码链接 */}
            <Link to="/RetrieveUsername" style={{ marginTop: '10px' }}>Forgot Username?</Link> {/* 新增找回用户名链接 */}        </div>
    );
}

export default UserLogin;
