// src/components/About.js
import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';

function About() {
    return (
        <div className="about-container">
                        <nav className="top-nav">
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/contact">Contact</Link>
            </nav>
                <h2>About the CI Question Bank</h2>
                <p>
                    This question bank is an essential resource for physicians preparing to take the ABPM Clinical Informatics (CI) board exam, regardless of whether you have completed a CI fellowship. Clinical informatics is an expansive field, and a common concern among exam candidates is understanding not only what will be tested but also how it will be tested. While the ABPM exam outline offers insight into the exam content, understanding the testing methodology is crucial. Some aspects of clinical informatics, though critical, are challenging to assess in a multiple-choice format. For instance, evaluating a physician's ability to implement and manage complex health information systems is difficult through standard questions. These systems often require hands-on experience and decision-making skills that are hard to encapsulate in a multiple-choice scenario.
                </p>
                <p>
                    Recognizing this, the CI Question Bank, with nearly 400 board-style questions, aims to prepare candidates for the exam's format and content. It helps you: Cover the majority of topics likely to be tested, and understand the probable question formats (yes, it does address questions such as “how will they test my coding skills?”). </p>
                    <p>It's important to note that the CI board exam differs significantly from typical clinical board exams. Many candidates, confident in their CI skills developed through practical experience, underestimate the exam. Remember, it's the theoretical knowledge and understanding of CI concepts that are primarily tested. My concept-based learning techniques, outlined in my book, are invaluable for this.
                </p>
                <p>
                    My book, complementing the question bank, adopts a concept-based approach to exam preparation. It includes some questions from the bank and is ideal for book learners or those with more time for exam preparation. Conversely, the question bank suits those who are busy and have fragmented time. Its expanding question set aims to cover all significant topics in CI. The question bank is particularly recommended for fellowship-trained professionals, since most of them is more familiar with the knowledge but lack the exam taking skills. However, for those following the practice pathway, combining the book with the question bank is advisable for a thorough preparation.
                </p>
                <p>
                    The book can be purchased through Amazon <a href="https://www.amazon.com/Clinical-Informatics-Board-Review-Questions/dp/B0CPB5WTT7/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1702029276&sr=8-1">here</a>.
                </p>
        </div>
    );
}

export default About;
