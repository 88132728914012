import React, { useState } from 'react';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const UploadQuestions = () => {

    const [file, setFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState('');
    const [duplicateQuestions, setDuplicateQuestions] = useState([]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const response = await axios.post(`${backendUrl}/api/upload-questions`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}` // 添加这行代码
                }
            });
    
            // Assuming the response is a string containing the message
            const responseMessage = response.data;
    
            // Extracting duplicate questions information from the message
            const duplicatesInfoStart = responseMessage.indexOf('Duplicates skipped: ');
            let duplicatesInfo = '';
            if (duplicatesInfoStart !== -1) {
                duplicatesInfo = responseMessage.substring(duplicatesInfoStart + 'Duplicates skipped: '.length);
            }
            const duplicateQuestionsList = duplicatesInfo.split(', ').filter(q => q);
    
            setUploadMessage(responseMessage);
            setDuplicateQuestions(duplicateQuestionsList);
        } catch (error) {
            console.error('Error uploading file', error);
            // 检查 error 对象是否有 response 属性
            if (error.response) {
                // 安全地访问 error.response.data
                setUploadMessage(error.response.data);
            } else {
                // 错误对象没有 response 属性，可能是网络问题或其他原因
                setUploadMessage('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload Questions</button>

            {uploadMessage && <div>{uploadMessage}</div>}

            {duplicateQuestions.length > 0 && (
                <div>
                    <h3>Duplicate Questions (not uploaded):</h3>
                    <ul>
                        {duplicateQuestions.map((text, index) => (
                            <li key={index}>{text}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default UploadQuestions;
