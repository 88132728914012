import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom'; // 引入 Link 组件
import './ResetPassword.css'; // 引入 CSS 文件
const backendUrl = process.env.REACT_APP_BACKEND_URL;
function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const { token } = useParams(); // 从 URL 获取令牌

    const handleSubmit = async (e) => {
        e.preventDefault();

        // 检查密码是否匹配
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        try {
            const response = await fetch(`${backendUrl}/api/reset-password/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newPassword }),
            });

            if (response.ok) {
                setMessage('Your password has been successfully reset. You can now ');
            } else {
                setMessage('Failed to reset password.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="reset-password-container">
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit} className="reset-password-form">
                <input 
                    type="password" 
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter new password" 
                    required
                />
                <input 
                    type="password" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm new password" 
                    required
                />
                <button type="submit">Reset Password</button>
            </form>
            {message && 
                <p className="reset-password-message">
                    {message} <Link to="/" className="reset-password-link">log in</Link>.
                </p>}
        </div>
    );
}

export default ResetPassword;
