import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import Link
import './Account.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
function Account() {
    const [userData, setUserData] = useState({
        username: '',
        isSubscriptionActive: false,
        subscriptionExpiration: null
    });

    useEffect(() => {
        // Fetch user data from backend
        const fetchUserData = async () => {
            // Replace with actual API call to get user data
            const response = await fetch(`${backendUrl}/get-user-data`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                setUserData({
                    username: data.username,
                    isSubscriptionActive: data.isSubscriptionActive,
                    subscriptionExpiration: data.subscriptionExpiration
                });
            }
        };

        fetchUserData();
    }, []);

        // ... Fetch user data logic ...

        const navigate = useNavigate();

        // Function to handle navigation to the appropriate dashboard
        const handleDashboardNavigation = () => {
            if (userData.isSubscriptionActive) {
                navigate('/dashboard');
            } else {
                navigate('/trial-dashboard');
            }
        };

    return (
        <div className="account-container">
            <h2>Account Information</h2>
            <p>Username: {userData.username}</p>
            <p>Subscription Status: {userData.isSubscriptionActive ? 'Subscribed' : 'Not Subscribed'}</p>
            {userData.isSubscriptionActive && (
                <p>Subscription Expiration Date: {userData.subscriptionExpiration}</p>
                
            )}
                        {/* Button to navigate to the appropriate dashboard */}
                        <button onClick={handleDashboardNavigation} className="dashboard-link">
                        Go to Dashboard
            </button>
        </div>
    );
}

export default Account;
