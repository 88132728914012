import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate
import './UserDashboard.css';
import './SharedStyles.css';
// import { Doughnut, Bar } from 'react-chartjs-2';
// import Chart from 'chart.js/auto';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
// import { Progress } from 'reactstrap'; // 引入reactstrap库中的Progress组件
const backendUrl = process.env.REACT_APP_BACKEND_URL;

async function fetchCompletedQuestions(userId) {
    try {
        const response = await axios.get(`${backendUrl}/api/user/${userId}/answered`);
        return response.data.answeredQuestionsCount;
    } catch (error) {
        console.error('Error fetching completed questions:', error);
        return 0; // Return 0 or handle the error as needed
    }
}
async function fetchTotalQuestions() {
    try {
        const response = await axios.get(`${backendUrl}/api/total-questions`);
        return response.data.totalQuestions;
    } catch (error) {
        console.error('Error fetching total questions:', error);
        return 0; // Return 0 or handle the error as needed
    }
}

function UserDashboard() {
    const navigate = useNavigate(); // Initialize navigate for redirection after logout
    const [totalCompletedQuestions, setTotalCompletedQuestions] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(0);
    // Category信息
    const [categories, setCategories] = useState([]);
    // 新增加的状态变量来追踪类别数据是否已更新
    const [categoriesUpdated, setCategoriesUpdated] = useState(false);
    const handleGoToReview = () => {
        navigate('/review'); // 确保这是你的 Review Screen 的路由
    };
    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                // 从后端获取每个类别的题目总数
                const response = await axios.get(`${backendUrl}/api/categories/question-count`);
                const fetchedCategories = response.data.map(category => ({
                    name: category.QuestionCategory,
                    totalQuestions: category.questionCount,
                    completedQuestions: 0, // 初始化已完成题目数量
                    // 其他需要的属性，例如 correctRate 等
                }));

                // 更新 categories 状态
                setCategories(fetchedCategories);
            } catch (error) {
                console.error('Error fetching category data:', error);
            }
        };

        fetchCategoryData();
    }, []);
    const [categoryCorrectRates, setCategoryCorrectRates] = useState({});

    useEffect(() => {
        // 从 localStorage 中检索并解码 JWT
        const token = localStorage.getItem('userToken');
        let userId = null;

        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                userId = decodedToken.userID;
            } catch (error) {
                console.error('Error decoding token:', error);
            }
        }

        if (userId) {
            const fetchCategoriesData = async () => {
                try {
                    const response = await axios.get(`${backendUrl}/api/categories/question-count`);
                    let fetchedCategories = response.data.map(category => ({
                        name: category.QuestionCategory,
                        totalQuestions: category.questionCount,
                        completedQuestions: 0,
                        correctRate: 0,
                    }));
    
                    const updates = fetchedCategories.map(async category => {
                        try {
                            const completedResponse = await axios.get(`${backendUrl}/api/questions/user/${userId}/answered/category/${category.name}`);
                            category.completedQuestions = completedResponse.data.answeredQuestionsCount;
    
                            const rateResponse = await axios.get(`${backendUrl}/api/questions/user/${userId}/answers`, {
                                params: { categories: category.name }
                            });
                            const answeredCount = rateResponse.data.correctCount + rateResponse.data.wrongCount;
                            category.correctRate = category.completedQuestions > 0 ? (rateResponse.data.correctCount / category.completedQuestions) * 100 : 0;
                        } catch (error) {
                            console.error(`Error fetching data for category ${category.name}:`, error);
                        }
                        return category;
                    });
    
                    fetchedCategories = await Promise.all(updates);
    
                    setCategories(fetchedCategories);
                    setCategoriesUpdated(true); 
                } catch (error) {
                    console.error('Error fetching category data:', error);
                }
            };
    
            fetchCategoriesData();
        }
    }, []); // 依赖项数组为空，确保仅在组件挂载时执行

    //const [selectedCategoriesForQuestions, setSelectedCategoriesForQuestions] = useState(categories.map(category => category.name));
    const [selectedCategoriesForReset, setSelectedCategoriesForReset] = useState(categories.map(category => category.name));
    // New state for selected categories for review
    const [selectedCategoriesForReview, setSelectedCategoriesForReview] = useState(categories.map(category => category.name));
    // Calculate overall statistics
    const overallStats = categories.reduce(
        (acc, category) => {
            acc.totalQuestions += category.totalQuestions;
            acc.completedQuestions += category.completedQuestions;
            acc.totalCorrectRate += category.correctRate;
            return acc;
        },
        { totalQuestions: 0, completedQuestions: 0, totalCorrectRate: 0 }
    );
    overallStats.overallCorrectRate = (overallStats.totalCorrectRate / categories.length).toFixed(2);

    // Doughnut Chart Data
//    const [doughnutData, setDoughnutData] = useState({
//        labels: ['Completed', 'Remaining'],
//        datasets: [
//            {
//                data: [0, 0], // Initialize with zero
//                backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)'],
//                borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
//                borderWidth: 1,
//            },
//        ],
//    });

    useEffect(() => {
        // Retrieve the token from local storage
        const token = localStorage.getItem('userToken');
        let userId = null;

        if (token) {
            // Decode the token to get the user ID
            try {
                const decodedToken = jwtDecode(token);
                userId = decodedToken.userID; // Adjust based on your token's structure
            } catch (error) {
                console.error('Error decoding token:', error);
            }
        }

        if (userId) {
            async function updateChartData() {
                const completedQuestions = await fetchCompletedQuestions(userId);
                const totalQuestions = await fetchTotalQuestions();
                const remainingQuestions = totalQuestions - completedQuestions;

//                setDoughnutData({
//                    labels: ['Completed', 'Remaining'],
//                    datasets: [
//                        {
//                            data: [completedQuestions, remainingQuestions],
//                            backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)'],
//                            borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
//                            borderWidth: 1,
//                        },
//                    ],
//                });
                setTotalCompletedQuestions(completedQuestions);
                setTotalQuestions(totalQuestions);
            }

            updateChartData();
        }
    }, []);




    // Bar Chart Data with Overall Correct Rate
//    const barData = {
//        labels: [...categories.map(category => category.name), 'Overall'],
//        datasets: [
//            {
//                label: 'Correct Rate (%)',
//                data: [...categories.map(category => category.correctRate), overallStats.overallCorrectRate],
//                backgroundColor: [...categories.map(() => 'rgba(75, 192, 192, 0.6)'), 'rgba(255, 159, 64, 0.6)'],
//                borderColor: [...categories.map(() => 'rgba(75, 192, 192, 1)'), 'rgba(255, 159, 64, 1)'],
//                borderWidth: 1,
//            },
//        ],
//    };

//    const barOptions = {
//        indexAxis: 'y', // 'x' for vertical bars, 'y' for horizontal bars
        // ... additional options as needed
//    };
    // Initialize selectedCategories with all category names only once at the beginning
    const [selectedCategories, setSelectedCategories] = useState(() => categories.map(category => category.name));

    const handleCategoryChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedCategories(selectedOptions);
    };

    const handleSelectAllToggle = () => {
        if (selectedCategories.length === categories.length) {
            // Deselect all if all are currently selected
            setSelectedCategories([]);
        } else {
            // Select all if not all categories are currently selected
            setSelectedCategories(categories.map(category => category.name));
        }
    };

    const handleContinue = async (e) => {
        e.preventDefault();
        console.log('Continue with unseen questions in categories:', selectedCategories);
    
        // 从 localStorage 中检索并解码 JWT 获取 userId
        const token = localStorage.getItem('userToken');
        let userId = null;
    
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                userId = decodedToken.userID;
            } catch (error) {
                console.error('Error decoding token:', error);
                return; // 如果无法获取 userId，则提前退出函数
            }
        }
    
        if (userId) {
            try {
                // 发起请求获取未完成的题目ID
                const response = await axios.get(`${backendUrl}/api/questions/user/${userId}/unanswered`, {
                    params: { categories: selectedCategories.join(',') }
                });
    
                const unansweredQuestionIds = response.data.unansweredQuestionIds;
    
                // 在此处跳转到 Question 组件，并传递未完成题目ID
                navigate('/question', { state: { unansweredQuestionIds } });
            } catch (error) {
                console.error('Error fetching unseen questions:', error);
            }
        } else {
            console.log('User ID not found');
        }
    };
    

    // Handler for reset category change
    const handleResetCategoryChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedCategoriesForReset(selectedOptions);
    };

    // Handler for reset select all toggle
    const handleResetSelectAllToggle = () => {
        if (selectedCategoriesForReset.length === categories.length) {
            setSelectedCategoriesForReset([]);
        } else {
            setSelectedCategoriesForReset(categories.map(category => category.name));
        }
    };

 

    const handleReset = async (e) => {
        e.preventDefault();
        // 确认对话框
        const isConfirmed = window.confirm("Are you sure you want to reset the questions? Reset cannot be undone.");
        if (isConfirmed) {
            console.log('Resetting categories:', selectedCategoriesForReset);
    
            const token = localStorage.getItem('userToken');
            let userId = null;
    
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    userId = decodedToken.userID;
                } catch (error) {
                    console.error('Error decoding token:', error);
                    return;
                }
            }
    
            if (userId) {
                try {
                    // 发起请求到后端的重置问题接口
                    await axios.post(`${backendUrl}/api/reset-questions`, {
                        userId: userId,
                        categories: selectedCategoriesForReset
                    });
                    // 请求成功后，可以选择更新类别状态或者重新加载类别数据
                    alert("Questions have been reset successfully.");
                    // 可选：重新加载类别数据
                    // fetchCategoriesData();
                } catch (error) {
                    console.error('Error resetting questions:', error);
                }
            }
        }
    };


        // Handler for review category change
        const handleReviewCategoryChange = (e) => {
            const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
            setSelectedCategoriesForReview(selectedOptions);
        };
    
        // Handler for review select all toggle
        const handleReviewSelectAllToggle = () => {
            if (selectedCategoriesForReview.length === categories.length) {
                setSelectedCategoriesForReview([]);
            } else {
                setSelectedCategoriesForReview(categories.map(category => category.name));
            }
        };
    
        const handleReview = (e) => {
            e.preventDefault();
            console.log('Reviewing finished questions in categories:', selectedCategoriesForReview);
            // Logic to fetch finished questions from selectedCategoriesForReview
        };
    
            // Function to handle user logout
    const handleLogout = () => {
        localStorage.removeItem('userToken'); // Remove the token from local storage
        navigate('/'); // Redirect to the login page or any other appropriate route
    };
        // 简化的进度条表示
        const renderCategoryProgress = () => {
            return categories.map((category, index) => (
                <div key={index} className="progress-container">
                    <label>{category.name}</label>
                    <div className="progress">
                        <div 
                            className="progress-bar" 
                            role="progressbar" 
                            style={{ width: `${(category.completedQuestions / category.totalQuestions) * 100}%` }}
                            aria-valuenow={category.completedQuestions}
                            aria-valuemin={0}
                            aria-valuemax={category.totalQuestions}
                        >
                            {`${category.completedQuestions}/${category.totalQuestions}`}
                        </div>
                    </div>
                </div>
            ));
        };
    
        // 简化的正确率条形图
        const renderCorrectRateBar = () => {
            if (!categoriesUpdated) {
                // 如果数据还没有更新完毕，可以返回一个加载指示器或者null
                return <div>Loading...</div>; // 或者返回null
            }
        
            return categories.map((category, index) => {
                const correctRate = category.correctRate || 0; // 直接使用 category.correctRate
        
                return (
                    <div key={index} className="correct-rate-container">
                        <label>{category.name}</label>
                        <div className="progress">
                            <div 
                                className="correct-rate-bar"
                                style={{ width: `${correctRate}%` }}
                            >
                                {`${correctRate.toFixed(2)}%`}
                            </div>
                        </div>
                    </div>
                );
            });
        };
        

    const totalCompletionPercentage = (totalCompletedQuestions / totalQuestions) * 100;

    return (
        <div className="user-dashboard">
                <div className="top-right-buttons">
                    <Link to="/account" className="top-right-button">Account</Link>
                    <button onClick={handleGoToReview} className="review-screen-button">Review</button>
                    <button onClick={handleLogout} className="logout-button">Logout</button>
                </div>
            <div className="dashboard-header">
                <h2>Welcome to Your Dashboard</h2>

            </div>

            {/* 总体进度条 */}
            <div className="progress-container">
                <label>Total Progress</label>
                <div className="progress">
                    <div 
                        className="progress-bar"
                        style={{ width: `${totalCompletionPercentage}%` }}
                    >
                        {`${totalCompletedQuestions}/${totalQuestions} Completed`}
                    </div>
                </div>
            </div>
            <div className="category-progress">
                <h3>Category Progress</h3>
                {renderCategoryProgress()}
            </div>
            <div className="correct-rate">
                <h3>Correct Rate by Category</h3>
                {renderCorrectRateBar()}
            </div>

            {/* Display overall statistics */}


            {/* Display category-wise statistics */}


            {/* Options to continue with unseen questions */}
            <section className="question-options">
                <form onSubmit={handleContinue}>
                    <label htmlFor="category-select">To continue with unseen questions: choose categories:</label>
                    <button type="button" onClick={handleSelectAllToggle}>
                        {selectedCategories.length === categories.length ? 'Deselect All' : 'Select All'}
                    </button>
                    <select id="category-select" multiple value={selectedCategories} onChange={handleCategoryChange}>
                        {categories.map((category, index) => (
                            <option key={index} value={category.name}>{category.name}</option>
                        ))}
                    </select>
                    <button type="submit">Continue</button>
                </form>
            </section>
                        {/* Review finished questions section 
            <section className="review-options">
                <form onSubmit={handleReview}>
                    <label htmlFor="review-category-select">Review finished questions in categories:</label>
                    <button type="button" onClick={handleReviewSelectAllToggle}>
                        {selectedCategoriesForReview.length === categories.length ? 'Deselect All' : 'Select All'}
                    </button>
                    <select id="review-category-select" multiple value={selectedCategoriesForReview} onChange={handleReviewCategoryChange}>
                        {categories.map((category, index) => (
                            <option key={index} value={category.name}>{category.name}</option>
                        ))}
                    </select>
                    <button type="submit">Review</button>
                </form>
            </section>*/}

            {/* Reset options */}
            <section className="reset-options">
                <form onSubmit={handleReset}>
                    <label htmlFor="reset-category-select">To reset questions, select categories:</label>
                    <button type="button" onClick={handleResetSelectAllToggle}>
                        {selectedCategoriesForReset.length === categories.length ? 'Deselect All' : 'Select All'}
                    </button>
                    <select id="reset-category-select" multiple value={selectedCategoriesForReset} onChange={handleResetCategoryChange}>
                        {categories.map((category, index) => (
                            <option key={index} value={category.name}>{category.name}</option>
                        ))}
                    </select>
                    <button type="submit">Reset</button>
                </form>
            </section>

        </div>
        
    );
}

export default UserDashboard;

/*
<div className="chart-container">
<div className="canvas-container">
    <Doughnut data={doughnutData} />
</div>
<div className="canvas-container">
    <Bar data={barData} options={barOptions} />
</div>
</div>*/