import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Question from './Question';
import axios from 'axios';
import './TrialDashboard.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
function TrialDashboard() {
    const [trialQuestionIds, setTrialQuestionIds] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTrialQuestions = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/questions/trial-questions`);
                setTrialQuestionIds(response.data);
            } catch (error) {
                console.error('Error fetching trial questions:', error);
            }
        };

        fetchTrialQuestions();
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        navigate('/');
    };
    console.log(trialQuestionIds)
    return (
        <div className="trial-dashboard-container">
            <div className="trial-dashboard-header">
                <h1>Welcome to the CI Question Bank Trial</h1>
                <div className="header-actions">
                    <Link to="/account" className="account-link">Account</Link>
                    <button onClick={handleLogout} className="logout-button">Logout</button>
                </div>
            </div>
            
            {/* 确保 Question 组件接收试用问题的 ID */}
            {trialQuestionIds.length > 0 && <Question hideTopRightButtons={true} trialQuestionIds={trialQuestionIds} />}
    
            <div className="subscription-info">
                <p>Enjoying the questions? Get full access to our question bank.</p>
                <p>Price for 1-year subscription: $200</p>
                <Link to="/payment" className="subscribe-button">Subscribe Now</Link>
            </div>
        </div>
    );
    
}

export default TrialDashboard;
