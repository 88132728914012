import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // 引入 Link 组件
import './RetrieveUsername.css'; // 引入 CSS 文件
const backendUrl = process.env.REACT_APP_BACKEND_URL;
function RetrieveUsername() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showLink, setShowLink] = useState(false); // 新增状态来控制链接的显示

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowLink(false); // 隐藏链接
        try {
            const response = await fetch(`${backendUrl}/api/retrieve-username`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setMessage('If your email is in our database, you will receive your username.');
                setShowLink(true); // 显示链接
            } else {
                setMessage('Error in retrieving username.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="retrieve-username-container">
            <h2>Retrieve Username</h2>
            <form onSubmit={handleSubmit} className="retrieve-username-form">
                <input 
                    type="email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email" 
                    required
                />
                <button type="submit">Retrieve Username</button>
            </form>
            {message && <p className="retrieve-username-message">{message}</p>}
            {showLink && <Link to="/" className="login-link">Go to Login</Link>} {/* 显示到登录页面的链接 */}
        </div>
    );
}

export default RetrieveUsername;
