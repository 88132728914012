import React, { createContext, useState, useEffect, useCallback } from 'react';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const UserContext = createContext({
    userData: { 
        isSubscriptionActive: false, 
        isAdmin: false // 添加 isAdmin 字段
    },
    setUserData: () => {}, // 设置函数
    isLoading: true, // 加载状态
});

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState({
        isSubscriptionActive: false,
        isAdmin: false // 初始化 isAdmin 状态
    });
    const [authToken, setAuthToken] = useState(localStorage.getItem('userToken'));
    const [isLoading, setIsLoading] = useState(true);

    const fetchUserDetails = useCallback(async () => {
        if (!authToken) return;

        try {
            const response = await fetch(`${backendUrl}/subscription-status`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setUserData({
                isSubscriptionActive: data.isSubscriptionActive,
                isAdmin: data.isAdmin // 更新 isAdmin 状态
            });
        } catch (error) {
            console.error('Error fetching user details:', error);
            setUserData({ isSubscriptionActive: false, isAdmin: false });
        }
    }, [authToken]);

    useEffect(() => {
        setIsLoading(true);
        fetchUserDetails().finally(() => setIsLoading(false));
    }, [fetchUserDetails]);

    useEffect(() => {
        const tokenUpdateHandler = () => {
            const newToken = localStorage.getItem('userToken');
            if (newToken !== authToken) {
                setAuthToken(newToken);
            }
        };

        window.addEventListener('storage', tokenUpdateHandler);

        return () => {
            window.removeEventListener('storage', tokenUpdateHandler);
        };
    }, [authToken]);

    return (
        <UserContext.Provider value={{ userData, setUserData, isLoading }}>
            {children}
        </UserContext.Provider>
    );
};
