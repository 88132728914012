import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // 引入 jwtDecode
import './ReviewScreen.css';
import './SharedStyles.css'; // 导入共享样式
import { useNavigate } from 'react-router-dom'; // React Router v6

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function ReviewScreen() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState({});
    const navigate = useNavigate(); // React Router v6
    const [categories, setCategories] = useState({});
    const getUserIdFromToken = () => {
        const token = localStorage.getItem('userToken');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                return decodedToken.userID; // 根据你的 token 结构调整
            } catch (error) {
                console.error('Error decoding token:', error);
                return null;
            }
        }
        return null;
    };
    // Dashboard按钮的事件处理函数
    const handleDashboardClick = () => {
        navigate('/dashboard'); // 导航到dashboard页面
    };
    // Log Out按钮的事件处理函数
    const handleLogoutClick = () => {
        localStorage.removeItem('userToken');
        navigate('/'); // 导航回登录页面
    };
    
    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('userToken'); // 从 localStorage 获取 token
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    const userId = decodedToken.userID; // 从解码的 token 中获取 userID

                    const response = await axios.get(`${backendUrl}/api/review/questions-data/${userId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    const data = response.data;
                    const groupedData = data.reduce((acc, question) => {
                        acc[question.category] = acc[question.category] || [];
                        acc[question.category].push(question);
                        return acc;
                    }, {});

                    setCategories(groupedData);
                } catch (error) {
                    console.error('Error fetching questions data:', error);
                }
            }
        };

        fetchData();
    }, []);

    const handleSearch = async () => {
        const userId = getUserIdFromToken(); // 获取用户ID
        if (!searchTerm) {
            alert('Please enter a keyword to search.'); // 如果没有输入搜索词，则提示用户
            return;
        }
    
        try {
            const response = await axios.get(`${backendUrl}/api/review/search`, {
                params: {
                    keyword: searchTerm,
                    userId: userId
                }
            });
    
            const searchResults = response.data;
            const groupedResults = searchResults.reduce((acc, question) => {
                acc[question.category] = acc[question.category] || [];
                acc[question.category].push(question);
                return acc;
            }, {});
    
            setSearchResults(groupedResults);
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const handleCellClick = (questionId) => {
        navigate(`/question/${questionId}`); // React Router v6
    };

    const renderQuestionsGrid = (questions) => {
        return (
            <div className="question-grid">
                {questions.map((question, idx) => (
                    <div key={idx} 
                         className={`question-cell ${question.status}`} 
                         onClick={() => handleCellClick(question.questionId)}>
                        <span>{question.questionId}</span>
                        {question.flagged && <span className="flag-icon">🚩</span>}
                    </div>
                ))}
            </div>
        );
    };
    // 在 renderCategoryTables 上方添加
    const renderSearchSection = () => {
        return (
            <div className="search-section">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search questions..."
                />
                <button onClick={handleSearch}>Search</button>
            </div>
        );
    };
    // 新的渲染搜索结果函数
    const renderSearchResults = () => {
        return Object.entries(searchResults).map(([categoryName, questions], index) => (
            <div key={index} className="category-section">
                <h3 className="category-title">{categoryName}</h3>
                {renderQuestionsGrid(questions)}
            </div>
        ));
    };
    const renderCategoryTables = () => {
        return Object.entries(categories).map(([categoryName, questions], index) => (
            <div key={index} className="category-section">
                <h3 className="category-title">{categoryName}</h3>
                <div className="question-grid">
                    {questions.map((question, idx) => (
                        <div key={idx} 
                             className={`question-cell ${question.status}`} 
                             onClick={() => handleCellClick(question.questionId)}>
                            <span>{question.questionId}</span>
                            {question.flagged && <span className="flag-icon">🚩</span>}
                        </div>
                    ))}
                </div>
            </div>
        ));
    };
    

    return (
        <div className="review-screen-container">
            {/* 在此处添加Dashboard和Log Out按钮 */}
            <div className="review-screen-header">
                <button onClick={handleDashboardClick} className='top-right-button'>Dashboard</button>
                <button onClick={handleLogoutClick} className='logout-button'>Log Out</button>
            </div>
            {renderSearchSection()}
{/*            {renderCategoryTables()}*/}
            {Object.keys(searchResults).length > 0 && <div className="search-results-container">
                <h2 classname="search-results-title">Search Results</h2>
                {renderSearchResults()}
            </div>}
            <h2>All Questions</h2>
            {renderCategoryTables(categories)}
        </div>
    );
}

export default ReviewScreen;
