import React, { useState } from 'react';
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode
import './Payment.css';
Modal.setAppElement('#root');
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const stripePromise = loadStripe("pk_live_51OGVDlEHbQSJYkrF1Yr5LO9Nig9dOrm9Y5ty7slfKwABdDYR8ubqgPTdWU0h1phbARSSsc7kGvbqwikFX10dZlzL00I6hzVLcU");

const Payment = () => {
  const [paymentError, setPaymentError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handlePayment = async () => {
    const stripe = await stripePromise;

    try {
      const token = localStorage.getItem('userToken');
      let userID = null;
      if (token) {
        const decodedToken = jwtDecode(token);
        userID = decodedToken.userID;
      }

      if (!userID) {
        setPaymentError('User not authenticated. Please login before purchasing.');
        return;
      }

      // Make an API request to your backend to create a Stripe Checkout session
      const response = await fetch(`${backendUrl}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userID: userID, // Send the userID to the backend
          lineItems: [
            {
              price: 'price_1OLNPIEHbQSJYkrFtriiy1Qy',
              quantity: 1,
            },
          ],
        }),
      });

      const session = await response.json();

      // Redirect the user to the Stripe Checkout page
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        setPaymentError(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setPaymentError('An error occurred during payment processing.');
    }
  };

  return (
    <div className="payment-container">
      <h2 className="payment-heading">Subscribe to the CI Question Bank</h2>
      <p className="payment-instruction">
        You are about to purchase a 1 year subscription ($200) to the CI question bank.
        Please click on the button below to finish payment with Stripe.
      </p>
      <button onClick={openModal}>View Service Agreement and Refund Policy</button>
      <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  contentLabel="Service Agreement and Refund Policy"
  className="Modal"
  overlayClassName="Overlay"
>
  <h2>CI Question Bank SERVICE AGREEMENT AND REFUND POLICY</h2>
  <div className="modal-content">
    <p>1. Introduction<br/>
       This Service Agreement and Refund Policy ("Agreement") is between Chengda Zhang MD MS and the subscriber ("Customer," "you"). By subscribing to our question bank service, you agree to be bound by the terms of this Agreement.</p>
    <p>2. Subscription Services<br/>
       The Company provides a subscription-based question bank service offering access to a variety of test preparation materials. The service is accessible online through our website or application.</p>
    <p>3. Subscription Term and Renewal<br/>
       Your subscription begins as soon as your initial payment is processed and will continue for the period selected during the subscription process.</p>
    <p>4. Payment<br/>
       You agree to pay the subscription fee at the rates in effect when the charges are incurred.</p>
    <p>5. Refund Policy<br/>
       * Eligibility for Refund: You are eligible for a full refund if you request it within 15 days of your subscription start date.<br/>
       * Condition for Refund: A full refund is only available provided that you have reviewed less than half of the total questions available in the question bank and have not downloaded any content.<br/>
       * Requesting a Refund: To request a refund, you must contact our customer service team within the 15-day period. Your request should include your subscription details and the reason for the refund request.<br/>
       * Processing Refunds: Refunds will be processed to your original method of payment within a certain number of business days after your request is approved.</p>
    <p>6. Use of Service<br/>
       You agree to use the question bank service solely for your personal and non-commercial use. Sharing of account details or content with third parties is strictly prohibited.</p>
    <p>7. Intellectual Property<br/>
       All content provided in the question bank is the property of the Company or its licensors and is protected by copyright and intellectual property laws.</p>
    <p>8. Disclaimer of Warranties<br/>
       The Company does not guarantee that the service will be uninterrupted or error-free, and the service is provided "as is" without warranties of any kind.</p>
    <p>9. Limitation of Liability<br/>
       The Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to this agreement.</p>
    <p>10. Modification of Terms<br/>
       The Company reserves the right to modify the terms of this Agreement at any time, with such modifications effective immediately upon posting.</p>
    <p>11. Governing Law<br/>
       This Agreement shall be governed by the laws of the jurisdiction in which the Company is registered.</p>
    <p>12. Contact Information<br/>
       For any questions or concerns regarding this Agreement or the service, please contact us at zhangcd07@outlook.com.</p>
  </div>
  <button onClick={closeModal} className="close-modal-button">Close</button>
</Modal>

      {paymentError && <p className="payment-error">Error: {paymentError}</p>}
      <button className="payment-button" onClick={handlePayment}>Pay with Stripe</button>
    </div>
  );
};


export default Payment;
