import React, { useState } from 'react';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DeleteQuestion = () => {
    const [questionId, setQuestionId] = useState('');
    const [deleteMessage, setDeleteMessage] = useState('');

    const handleQuestionIdChange = (event) => {
        setQuestionId(event.target.value);
    };

    const handleDelete = async () => {
        // 获取存储在 localStorage 中的 token
        const token = localStorage.getItem('userToken');
        
        try {
            const response = await axios.delete(`${backendUrl}/api/delete-question`, {
                headers: {
                    // 在请求头中添加 token
                    Authorization: `Bearer ${token}`
                },
                data: { questionId } // Send questionId in the request body
            });

            setDeleteMessage(response.data);
        } catch (error) {
            console.error('Error deleting question', error);
            setDeleteMessage(error.response ? error.response.data : 'Error deleting question');
        }
    };

    return (
        <div>
            <input 
                type="text" 
                value={questionId} 
                onChange={handleQuestionIdChange} 
                placeholder="Enter Question ID to Delete"
            />
            <button onClick={handleDelete}>Delete Question</button>
            {deleteMessage && <div>{deleteMessage}</div>}
        </div>
    );
};

export default DeleteQuestion;
