import React, { useState } from 'react';
import './ForgotPassword.css'; // Adjust the path as needed

const backendUrl = process.env.REACT_APP_BACKEND_URL;
function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${backendUrl}/api/reset-password-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
    
            if (response.ok) {
                setMessage('If your email is in our database, you will receive a password reset link.');
            } else {
                setMessage('Error in sending password reset link.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="forgot-password-container">
            <h2>Forgot Password</h2>
            <form onSubmit={handleSubmit} className="forgot-password-form">
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Enter your email" 
                />
                <button type="submit">Send Reset Link</button>
            </form>
            {message && <p className="forgot-password-message">{message}</p>}
        </div>
    );
}

export default ForgotPassword;
