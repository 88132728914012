import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../UserContext'; // 确保路径正确

const AdminRoute = ({ children }) => {
    const { userData, isLoading } = useContext(UserContext); // 获取整个 userData 对象以及 isLoading 状态

    // 在用户数据加载时显示加载提示
    if (isLoading) {
        return <div>Loading...</div>;
    }

    // 检查用户是否为管理员
    if (!userData || !userData.isAdmin) {
        // 如果用户不是管理员或用户数据尚未加载，重定向到主页
        return <Navigate to="/" />;
    }

    return children; // 如果用户是管理员，渲染子组件
};

export default AdminRoute;
