// src/components/Contact.js
import './Contact.css';
import React from 'react';
import { Link } from 'react-router-dom';

function Contact() {
    return (
        <div className="contact-container">
                        <nav className="top-nav">
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/contact">Contact</Link>
            </nav>
            <h2>Contact Information</h2>
            <p>
                    Please do not hesitate to contact me should you have any questions or suggestions. </p>
                    <p> Chengda Zhang, MD MS </p>
                    <p><a href="mailto:zhangcd07@outlook.com">zhangcd07@outlook.com</a> 
                </p>
        </div>
    );
}

export default Contact;
