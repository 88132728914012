import React, { useState } from 'react';
import axios from 'axios';
import './EditUser.css'; // 引入样式文件

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const UserEditor = () => {
    const [inputEmail, setInputEmail] = useState('');
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchUser = async () => {
        const token = localStorage.getItem('userToken');
        try {
            setLoading(true);
            const response = await axios.get(`${backendUrl}/api/get-user/${inputEmail}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUser(response.data);
            setLoading(false);
        } catch (err) {
            setError('Error fetching user data');
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setInputEmail(e.target.value);
    };

    const handleUserChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleFetchClick = () => {
        if (inputEmail) {
            fetchUser();
        } else {
            setError('Please enter a valid email');
        }
    };

    const updateUser = async () => {
        const token = localStorage.getItem('userToken');
        try {
            setLoading(true);
            await axios.put(`${backendUrl}/api/edit-user/${inputEmail}`, user, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            alert('User updated successfully!');
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="editor-container">
            <input
                type="text"
                value={inputEmail}
                onChange={handleInputChange}
                placeholder="Enter User Email"
                className="input-field"
            />
            <button onClick={handleFetchClick} className="button">Fetch User</button>

            {user && (
                <div>
                    <h2>Edit User</h2>
                    <div>
                        <label className="label">Username:</label>
                        <input
                            type="text"
                            name="Username"
                            value={user.Username || ''}
                            onChange={handleUserChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                        <label className="label">Email:</label>
                        <input
                            type="email"
                            name="Email"
                            value={user.Email || ''}
                            onChange={handleUserChange}
                            className="input-field"
                            disabled // 通常电子邮件地址不应被更改
                        />
                    </div>
                    <div>
                        <label className="label">Password:</label>
                        <input
                            type="password"
                            name="Password"
                            value={user.Password || ''}
                            onChange={handleUserChange}
                            className="input-field"
                        />
                    </div>
                    <div>
                            <label className="label">Subscription Expiration Time:</label>
                            <input
                                type="date"
                                name="Subscription_expiration_time"
                                value={formatDate(user.Subscription_expiration_time)}
                                onChange={handleUserChange}
                                className="input-field"
                            />
                        </div>
                        <div>
                            <label className="label">Registration Date:</label>
                            <input
                                type="date"
                                name="Registration_date"
                                value={formatDate(user.Registration_date)}
                                onChange={handleUserChange}
                                className="input-field"
                                disabled // 通常注册日期不应被更改
                            />
                        </div>
                        <div>
                            <label className="label">Last Login:</label>
                            <input
                                type="date"
                                name="Last_login"
                                value={formatDate(user.Last_login)}
                                onChange={handleUserChange}
                                className="input-field"
                                disabled // 通常最后登录时间不应被更改
                            />
                        </div>
                        <div>
                            <label className="label">Reset Password Token:</label>
                            <input
                                type="text"
                                name="resetPasswordToken"
                                value={user.resetPasswordToken || ''}
                                onChange={handleUserChange}
                                className="input-field"
                            />
                        </div>
                        <div>
                            <label className="label">Reset Password Expires:</label>
                            <input
                                type="date"
                                name="resetPasswordExpires"
                                value={formatDate(user.resetPasswordExpires)}
                                onChange={handleUserChange}
                                className="input-field"
                            />
                        </div>
                        <div>
                            <label className="label">Is Admin:</label>
                            <input
                                type="checkbox"
                                name="IsAdmin"
                                checked={user.IsAdmin}
                                onChange={(e) => setUser({ ...user, IsAdmin: e.target.checked })}
                                className="input-field"
                            />
                        </div>
                    
                    <button onClick={updateUser} className="button">Update User</button>
                </div>
            )}
        </div>
    );
};

export default UserEditor;
