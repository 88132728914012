import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UserLogin from './UserLogin.js';
import './FrontPage.css';

function FrontPage() {
    const [showFullIntro, setShowFullIntro] = useState(false);

    return (
        <div className="front-page-container">
            <nav className="top-nav">
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/contact">Contact</Link>
            </nav>
            <h1>CI Question Bank</h1>
            <UserLogin />
            <div className="signup-prominent">
                <Link to="/signup" className="signup-button">Sign Up for a Free Trial</Link>
            </div>
            {/* Key Features */}

            <div className="key-features">
                <h2>Why Choose Our CI Question Bank?</h2>
                <ul>
                    <li>Nearly 400 board-style questions covering all major topics.</li>
                    <li>Realistic practice for the ABPM CI board exam format.</li>
                    <li>Designed for both fellowship-trained professionals and self-learners.</li>
                    <li>Complementary to concept-based learning techniques.</li>
                </ul>

            </div>

            {/* Full Introduction - Collapsible Section */}
            {showFullIntro && (
                <div className="full-introduction">


                </div>
            )}

            {/* Prominent Sign Up Link */}


                <footer className="footer">
                <p>&copy; {new Date().getFullYear()} Chengda Zhang MD MS. All Rights Reserved.</p>
            </footer>
        </div>
    );
}

export default FrontPage;